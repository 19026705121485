.collapsableContainer {
    display: grid;
    grid-template-rows: 0fr;
    transition:
        grid-template-rows 0.3s ease-out,
        opacity 0.3s ease-out;
    opacity: 0;

    &--open {
        grid-template-rows: 1fr;
        opacity: 1;
    }
}

.icon {
    display: block;
    transition: 0.5s transform ease;
    transform: scale(1, -1);

    &--open {
        transform: scale(1, 1);
    }
}
